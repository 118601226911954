import styled, { css } from 'styled-components';

import { squareGridBackground } from '../../Layouts/LayoutWrap/GlobalStyles';

const styledGridBackgroundAfterBefore = css`
  content: '';
  position: absolute;
  height: 38rem;
  display: block;
  z-index: 1;
`;

export const SquareGridBackground = styled.div`
  ${squareGridBackground};
  position: relative;
  > * {
    z-index: 2;
    position: relative;
  }
  ${({ fadeOut }) => fadeOut && `
    &:after {
      ${styledGridBackgroundAfterBefore};
      bottom: 0; left: 0; right: 0;
      background: linear-gradient(0deg,  0%, rgba(0,0,0,0) 100%);
    }
  `};
  ${({ fadein }) => fadein === 'true' && `
    &:before {
      ${styledGridBackgroundAfterBefore};
      top: 0; left: 0; right: 0;
      background: linear-gradient(180deg, #fff 0%, rgba(0,0,0,0) 100%);
    }
  `};
  ${({ darkOut }) => darkOut && `
    &:after {
      ${styledGridBackgroundAfterBefore};
      bottom: 0; left: 0; right: 0;
      background: linear-gradient(0deg, #BFBBAC 0%, rgba(0, 0, 0, 0) 100%);
    }
  `};
`;
