import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { containerMixin, responsiveQuery } from '../components/Layouts/LayoutWrap/GlobalStyles';
import MainLayout from '../components/Layouts/MainLayout/MainLayout';
import Seo from '../components/Misc/Seo/Seo';
import BackgroundImage from '../components/Misc/Image/BackgroundImage';
import { Heading, HR, P, SectionHeader } from '../components/Misc/Typogrpahy/Typography';
import Image from '../components/Misc/Image/Image';
import { SquareGridBackground } from '../components/Misc/Decorative/Decorative';

const SectionContent = styled.div`
  ${containerMixin};
`;
const HeroImage = styled(BackgroundImage)`
  height: 60rem;
  ${responsiveQuery.mobileL`
    height: 70vh;
  `};
`;
const AboutSection = styled.section`
  position: relative;
  z-index: 10;
  padding: 20rem 0 20rem;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0; left: 0; right: 0;
    display: block;
    height: 28rem;
    background: linear-gradient(0deg, #BFBBAC 0%, rgba(0, 0, 0, 0) 100%);
  }
  ${SectionHeader} {
    max-width: calc(100% - calc(2 * var(--grid-cut)));
    margin: -10rem auto 8rem;
    padding-top: 5rem;
    z-index: 2;
    background-color: #fff;
    position: relative;
    ${responsiveQuery.laptop`
      padding: 5rem 2rem 0;
      max-width: 100%;
    `};
  }
`;
const TeamSection = styled.section`
  position: relative;
  z-index: 10;
  ${SectionHeader} {
    max-width: calc(100% - calc(2 * var(--grid-cut)));
    margin: -10rem auto 8rem;
    padding-top: 5rem;
    z-index: 2;
    background-color: #fff;
    position: relative;
    ${responsiveQuery.laptop`
      padding: 5rem 2rem 0;
      max-width: 100%;
    `};
  }
  ${SectionContent}:last-of-type {
    padding-bottom: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${responsiveQuery.mobileL`
      svg {
        display: none;
      }
    `};
    > ${Heading} {
      text-align: center;
      margin-bottom: 7rem;
    }
  }

  .people-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;;
    flex-wrap: wrap;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0; left: 0; right: 0;
      display: block;
      height: 28rem;
      background: linear-gradient(0deg, #BFBBAC 0%, rgba(0, 0, 0, 0) 100%);
    }

    .person-wrapper {
      width: 245px;
      height: 465px;

      .gatsby-image-wrapper {
        height: 350px !important;

        img { object-fit: cover; }
      }
    }

    @media screen and (max-width: 910px) {
      flex-direction: column;

      .person-wrapper:not(:last-child) { margin-bottom: 40px; }
    }
  }
`;



// const TeamStructureItem = styled.li`
//   background-color: #C9C5B6;
//   border-radius: 1rem;
//   padding: 0 3rem;
//   flex: 0 0 26%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   margin: 2rem;
//   min-height: 12rem;
//   ${responsiveQuery.mobileL`
//     flex: 0 0 80%;
//     margin: 0 0 2rem;
//   `};
//   ${responsiveQuery.mobileS`
//     flex: 0 0 100%;
//   `};
// `;
// const TeamStructureList = styled.ul`
//   list-style: none;
//   display: none;
//   justify-content: center;
//   flex-wrap: wrap;
//   max-width: 70rem;
//   margin: 0 auto;
//   ${responsiveQuery.mobileL`
//     display: flex;
//   `};
// `;
// const TeamStructure = ({ structureData }) => (
//   <TeamStructureList>
//     {structureData.length > 0 && structureData.map(({ name }, index) => (
//       <TeamStructureItem key={index}><Heading as={'h5'}>{name}</Heading></TeamStructureItem>
//     ))}
//   </TeamStructureList>
// );
const CertificatesSection = styled.section`
  position: relative;
  z-index: 10;
  padding-bottom: 14rem;
  ${SectionHeader} {
    max-width: calc(100% - calc(2 * var(--grid-cut)));
    margin: -10rem auto 8rem;
    padding-top: 5rem;
    z-index: 2;
    background-color: #fff;
    position: relative;
    ${responsiveQuery.laptop`
      padding: 5rem 2rem 0;
      max-width: 100%;
    `};
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0; left: 0; right: 0;
    display: block;
    height: 28rem;
    background: linear-gradient(0deg, #BFBBAC 0%, rgba(0, 0, 0, 0) 100%);
  }
`;
const CompaniesLogoItem = styled.li`
  width: 100%;
  max-width: 15rem;
  margin: 0 2rem 2rem;
  ${responsiveQuery.tablet`
    flex: 0 0 33%;
  `};
  ${responsiveQuery.mobileL`
    margin: 0 0 2rem;
    flex: 0 0 100%;
  `};
`;
const CompaniesLogoList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  ${responsiveQuery.mobileL`
    justify-content: center;
  `};
   ${responsiveQuery.mobileS`
    flex-direction: column;
  `};
`;
const Certificates = ({ certificatesData }) => (
  <CompaniesLogoList>
    {certificatesData.length > 0 && certificatesData.map(({ image, name }, index) => (
      <CompaniesLogoItem key={index}>
        <Image filename={image} fluid={false} alt={name} style={{ maxWidth:'100%', maxHeight:'150px' }} />
      </CompaniesLogoItem>
    ))}
  </CompaniesLogoList>
);
const CommitmentSectionTag = styled.section`
  ${SectionContent} {
    padding-top: 10rem;
    padding-bottom: 10rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: calc(100% - (2 * var(--grid-cut)));
    ${responsiveQuery.desktop`
      max-width: var(--max-width-desktop);
    `}
    ${responsiveQuery.tablet`
      max-width: 100%;
    `};
    ${responsiveQuery.mobileL`
      flex-direction: column;
      justify-content: center;
    `};
  }
`;
const CommitmentItem = styled.div`
  margin-bottom: 3rem;
  flex: 0 0 45%;
  ${Heading} {
    margin-bottom: 3rem;
  }
  ${HR} {
    margin-bottom: 3rem;
  }
`;
const CommitmentSection = ({ commitmentData }) => (
  <CommitmentSectionTag>
    <SquareGridBackground fadein='true'>
      <SectionContent>
        {commitmentData.length > 0 && commitmentData.map(({ icon, title, description }, index) => (
          <CommitmentItem key={index}>
            <Image style={{height: '70px', width: '70px', marginBottom: '30px'}} filename={icon} fluid={false} alt={title} />
            <HR />
            <Heading as={'h3'}>{title}</Heading>
            <P style={{textAlign: 'justify'}}>{description}</P>
          </CommitmentItem>
        ))}
      </SectionContent>
    </SquareGridBackground>
  </CommitmentSectionTag>
);

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query AboutPageQuery {
      site {
        siteMetadata {
          pages {
            about {
              metaTitle
              title
              description
              heroImage
              teamSection {
                title
                description
                structure {
                  title
                  items {
                    name
                    position
                    image
                  }
                }
              }
              certificatesSection {
                title
                items {
                  image
                  name
                }
              }
              commitmentSection {
                icon
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const {
    metaTitle,
    title,
    description,
    heroImage,
    teamSection,
    certificatesSection,
    commitmentSection,
  } = data.site.siteMetadata.pages.about;
  return (
    <MainLayout>
      <Seo title={metaTitle} />
      <HeroImage filename={heroImage} />
      <AboutSection>
        <SectionContent>
          <SectionHeader>
            <Heading as='h2'>{title}</Heading>
            <HR />
            <P>{description}</P>
          </SectionHeader>
        </SectionContent>
      </AboutSection>
      <TeamSection>
        <SectionContent>
          <SectionHeader>
            <Heading as='h2'>{teamSection.title}</Heading>
            <HR />
            <P>{teamSection.description}</P>
          </SectionHeader>
        </SectionContent>

        <SectionContent>
          <div className="people-wrapper">
              {
                teamSection.structure.items.map(({ name, position, image }) => {
                  console.log('name, position, image', name, position, image);
                  return (
                    <div className="person-wrapper" key={name}>
                      <Image style={{width: '100%', height: '100%'}} filename={image} fluid={false} alt={name} />
                      <div className="name" style={{fontSize: '25px', color: '#191919', paddingTop: '20px'}}>{ name }</div>
                      <div className="position" style={{fontSize: '20px', color: '#979384', paddingTop: '10px'}}>{ position }</div>
                    </div>
                  )
                })
              }
          </div>
        </SectionContent>
      </TeamSection>
      <CertificatesSection>
        <SectionContent>
          <SectionHeader>
            <HR />
            <Heading as='h2'>{certificatesSection.title}</Heading>
          </SectionHeader>
          <Certificates certificatesData={certificatesSection.items} />
        </SectionContent>
      </CertificatesSection>
      <CommitmentSection commitmentData={commitmentSection} />
    </MainLayout>
  );
};

export default AboutUs;
